import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Footer from "../../../components/Footer";
import WhatsappChat from "../../../components/WhatsappChat";
import { localStorageKeys } from "../../../utility/localstorage";
import { getTranslatedText } from "../../../utility/constant";
import RangeSliderSection from "../../../components/RangeSlider";
import Bike from "../../../images/products/adBike.png";

const CalculatorIndex = ({}) => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  const [selectedMonths, setSelectedMonths] = useState(50);
  const [selectedPayment, setSelectedPayment] = useState(0);
  const [cost, setCost] = useState("137,614");
  const [rent, setRent] = useState("8,461.26");
  const [payment, setPayment] = useState("159,785");
  const [profit, setProfit] = useState("16%");

  const changeTenurePlan = (value) => {
    setSelectedMonths(value);
    switch (value) {
      case 0:
        setCost("137,614");
        setRent("29,087.9");
        setPayment("145,514");
        setProfit("6%");
        break;
      case 25:
        setCost("137,614");
        setRent("15,311.8");
        setPayment("150,118");
        setProfit("9%");
        break;
      case 50:
        setCost("137,614");
        setRent("8,461.26");
        setPayment("159,785");
        setProfit("16%");
        break;
      case 75:
        setCost("144,987");
        setRent("6,774.29");
        setPayment("180,187");
        setProfit("24.2%");
        break;
      case 100:
        setCost("152,359");
        setRent("6,037.47");
        setPayment("203,149");
        setProfit("33.3%");
      default:
        break;
    }
  };

  const changeDownPaymentPlan = (value) => {
    setSelectedPayment(value);
    switch (value) {
      case 0:
        setCost("137,614");
        setRent("29,087.9");
        setPayment("145,514");
        setProfit("6%");
        break;
      case 25:
        setCost("137,614");
        setRent("15,311.8");
        setPayment("150,118");
        setProfit("9%");
        break;
      case 50:
        setCost("137,614");
        setRent("8,461.26");
        setPayment("159,785");
        setProfit("16%");
        break;
      case 75:
        setCost("144,987");
        setRent("6,774.29");
        setPayment("180,187");
        setProfit("24.2%");
        break;
      case 100:
        setCost("152,359");
        setRent("6,037.47");
        setPayment("203,149");
        setProfit("33.3%");
      default:
        break;
    }
  };

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <RangeSliderSection
        headlineOne="Start Riding Your"
        headlineColor="Honda CD 70"
        headlineThree="by paying as low as"
        markHeadline="Rs 11,650"
        bikeImage={Bike}
        valueTenure={selectedMonths}
        valuePayment={selectedPayment}
        changeTenurePlan={changeTenurePlan}
        changeDownPaymentPlan={changeDownPaymentPlan}
        cost={cost}
        rent={rent}
        payment={payment}
        profit={profit}
      />

      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <WhatsappChat />
    </Wrapper>
  );
};

export default CalculatorIndex;
